






















import Vue from "vue";
import i18n from "@/i18n";
export default Vue.extend({
  name: "AssessmentSettingSelectField",
  data() {
    return {
      field_val: null as string | null
    };
  },
  created() {
    this.field_val = this.default_value;
  },
  props: {
    default_value: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    items_text: {
      type: String,
      default: "title"
    },
    items_value: {
      type: String,
      default: "value"
    },
    placeholder: {
      type: String,
      default: ""
    },
    rules: {
      type: Array,
      default: () => [(v: string) => !!v || i18n.t("rules.required")]
    },
    type: {
      type: String,
      required: true
    }
  },
  methods: {
    update_value() {
      this.$emit("update_value", {
        value: this.field_val,
        type: this.type
      });
    }
  }
});
